<template>
  <div class="onboardingMainBox">
      <div class="onboardingAsideBlock">
          <div class="onboardingAsideFlexBox onboardingSideFlexBox">
              <div class="onboardingAsideTxtBox">
                  <h1>Create your Free <span>Online Store</span> on Yeetcommerce.com</h1>
              </div>
              <div class="onBoardingCoverImage">
                  <img class="onboardingAsideCoverImg" src="@/assets/images/yeet/yeet_dashboard.png" alt="">
                  <div class="boardingStatImg1">
                      <img src="@/assets/images/ordersValueImage.png" alt="">
                  </div>
                  <div class="boardingStatImg2">
                      <img src="@/assets/images/listingImage.png" alt="">
                  </div>
                  <div class="boardingStatImg3">
                      <img src="@/assets/images/visitorsImage.png" alt="">
                  </div>
              </div>
          </div>
      </div>


      <!-- Step 1 -->      
      <div class="onboardingRightsideBlock">
          <div>
            <div v-for="(message, index) in messages" :key="index" class="message">
              {{ message.text }}
            </div>
            <template v-if="!error">
              <input v-model="currentMessage" @keyup.enter="sendMessage" type="text" placeholder="Type your message...">
            </template>
            <template v-else>
              <p>{{ error }}</p>
            </template>
          </div>
      </div>

  </div>
</template>

<script>
// import Chatbot from './chat';
export default {
  name: 'ChatAi',
  components: {
    // Chatbot
  },
  data() {
    return {
      messages: [
        {
          text: "Welcome to YeetCommerce! Here's your personal assistant to swiftly create your online store in just a few minutes.",
          isBot: true
        }
      ],
      currentMessage: '',
      currentStep : 0,
      questions: [
        "What's your email address?",
        "Please enter the OTP you received:",
        "What's your first name?",
        "What's your last name?",
        "What's your phone number with country code?",
        "Would you like to use a system-generated password? (Yes/No)",
        "Please upload your brand/website logo:",
        "Enter your country:",
        "Select your store category:",
        "Enter your store domain:",
        "Select a theme:",
        "Please agree to our terms and conditions. (Yes/No)",
      ],
      error: null
    };
  },
  methods: {
    async sendMessage() {
      if (this.currentMessage.trim() === '') return;
      this.messages.push({ text: this.currentMessage, isBot: false });
      await this.getResponse(this.currentMessage);
      // this.messages.push({ text: response, isBot: true });
      // this.currentMessage = '';
    },
    async getResponse(message) {
      switch(this.currentStep){
        case 1:
          
        break;
      }
      const prompt = this.questions[this.currentStep];
      // if
      this.currentStep++;
      return prompt;
    },
    async getOpenAIResponse(obj){
      try{
        let res = await this.$axios.post('/ai/complete',obj);
        return res.data.data;
      }catch(ex){
        let error = ex.response ? ex.response.data.message : ex.message;
        this.error = error;
        this.$notify({
          message: error,
          type: 'error'
        })
      }
    }
  },
  beforeMount(){
    let question = this.questions[this.currentStep];
    this.currentStep++;
    this.messages.push({ text: question, isBot: true });
  }
};
</script>

<style>
.message {
  margin: 5px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
}
  .onBoardingAsideTxtBox{
      display: flex;
      height: 100%;
      flex-direction: column;
      padding-top: 64px;
      flex: 1;
  }
  .onBoardingCoverImage{
      width: 100%;
      position: relative;
      background-color: #fff;
      flex: 1;
      display: flex;
      left:64px;
      /* margin-bottom: 100px; */
      box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  }
  .onboardingAsideCoverImg{
      width: 100%;
      height: 100%;
      object-fit: fill;
  }
  .boardingStatImg1,.boardingStatImg2,.boardingStatImg3{
      position: absolute;
      animation: zoom-in-zoom-out 1s ease infinite;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
      border-radius: 20px;
      overflow: hidden;
  }
  .boardingStatImg1 img,.boardingStatImg2 img,.boardingStatImg3 img{
      width: 100%;
      height: 100%;
      object-fit: fill;
  }
  .boardingStatImg1{
      width: 288px;
      height:97px;
      top: -63px;
      right: -69px;
  }
  .boardingStatImg2{
      right: -88px;
      bottom: 85px;
      width:198px;
      height: 172px;
  }
  .boardingStatImg3{
      width: 200px;
      height: 180px;
      bottom: -77px;
      left: -37px;
  }
  .whatsappIcon{
      position: fixed;
      bottom: 1%;
      left: 1%;
      width: 60px;
      height: 60px;
      z-index: 99;
  }
  .whatsappIcon img{
      width: 100%;
      height: 100%;
      object-fit: contain;
  }
  @media screen and (max-width: 1600px) and (min-width: 1537px) {
      .onboardingAsideTxtBox{
          margin-bottom: 120px;
      }
      .onBoardingCoverImage{
          margin-bottom: 0;
      }
      .boardingStatImg1 {
          width: 245px;
          height: 81px;
          top: -55px;
          right: -52px;
      }
      .boardingStatImg2 {
          right: -80px;
          bottom: 56px;
          width: 180px;
          height: 160px;
      }
      .boardingStatImg3 {
          width: 170px;
          height: 145px;
          bottom: -60px;
          left: -37px;
      }
  }
  @media screen and (max-width: 1536px) and (min-width: 1367px) {
      .onboardingAsideTxtBox{
          margin-bottom: 140px;
      }
      .onBoardingCoverImage{
          margin-bottom: 0;
      }
      .boardingStatImg1 {
          width: 245px;
          height: 81px;
          top: -60px;
          right: -52px;
      }
      .boardingStatImg2 {
          right: -80px;
          bottom: 50px;
          width: 180px;
          height: 160px;
      }
      .boardingStatImg3 {
          width: 170px;
          height: 145px;
          bottom: -70px;
          left: -37px;
      }
  } 
  @media screen and (max-width: 1366px) and (min-width: 1025px) {
      .onboardingAsideTxtBox {
          margin-bottom: 145px;
      }
      .onBoardingCoverImage{
          margin-bottom: 0;
      }
      .boardingStatImg1 {
          width: 225px;
          height: 70px;
          top: -49px;
          right: -52px;
      }
      .boardingStatImg2 {
          right: -74px;
          bottom: 49px;
          width: 158px;
          height: 135px;
      }
      .boardingStatImg3 {
          width: 141px;
          height: 121px;
          bottom: -35px;
          left: -37px;
      }
  }
  @media screen and (max-height: 1010px) and (min-height: 910px){
      .onboardingAsideTxtBox {
          margin-bottom: 95px;
      }
      .onBoardingCoverImage {
          height: 540px;
      }
      .boardingStatImg1 {
          width: 245px;
          height: 81px;
          top: -55px;
          right: -52px;
      }
      .boardingStatImg2 {
          right: -80px;
          bottom: 56px;
          width: 180px;
          height: 160px;
      }
      .boardingStatImg3 {
          width: 170px;
          height: 145px;
          bottom: -60px;
          left: -37px;
      }

      /* .onboardingAsideTxtBox{
          margin-bottom: 60px;
      }
      .onboardingAsideCoverImg{
          width: 923px!important;
          height: 570px!important;
          object-fit: fill;
      } */
  }




  .otp-input {
      width: 50px;
      height: 50px;
      padding: 5px;
      margin: 0 10px;
      font-size: 20px;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      text-align: center;
  }
  .error {
      border: 1px solid red !important;
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  .el-radio-group label{
      height: 100%;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
  }
  .el-radio-group .el-radio-button .el-radio-button__inner{
      margin-left: 0px !important;
      transition: all 0.3s ease-in-out;
      display: inline-block;
      padding: 10px 33px;
  }
  .el-radio-group .el-radio-button .el-radio-button__inner:hover{
      margin-left: 0px !important;
      background-color:#fd4e5d;
      color:#fff;
      transition: all 0.3s ease-in-out;
      display: inline-block;
  }
  .el-radio-group .el-radio-button.is-active .el-radio-button__inner{
      background-color: #15223D;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      padding: 10px 33px;
      text-transform: uppercase;
      margin-left: 0px !important;
      border-color: #15223D;
      transition: all 0.3s ease-in-out;
      display: inline-block;
  }
  .startedFormBox.passwordField{
      position: relative;
  }
  .startedFormBox.passwordField .PasswordEyeBtn{
      background-color: transparent;
      padding: 0px;
      margin: 0px;
      border: none;
      width: 17px;
      height: 17px;
      position: absolute;
      top: 50px;
      right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  .startedFormBox.passwordField .genPasswordBtn{
      top: 18px;
  }
  

</style>